




































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import { apiAccountLog, apiBwChangeType } from '@/api/finance/finance'
import { RequestPaging } from '@/utils/util'
import LsPagination from '@/components/ls-pagination.vue'
import ExportData from '@/components/export-data/index.vue'
@Component({
    components: {
        LsPagination,
        ExportData
    }
})
export default class AccountLog extends Vue {
    /** S Data **/
    tableData = []
    pickerOptions = []
    apply_port_type = '1'
    // 顶部查询表单
    pager: RequestPaging = new RequestPaging()
    infoType = '1' // 选择用户编号0 选择用户昵称1 手机号码2
    // 顶部查询表单
    form = {
        apply_port_type: '1', // 用户或配送员
        nickname: '', // 用户昵称
        sn: '', // 用户编号
        changeType: '', // 变动类型
        start_time: '',
        end_time: '',
        mobile: '' // 手机号
    }
    // 变动类型数据
    changeTypeList = []

    apiAccountLog = apiAccountLog
    /** E Data **/

    // 监听用户信息查询框条件
    @Watch('infoType', {
        immediate: true
    })
    getChange(val: any) {
        // 初始值
        this.form.sn = ''
        this.form.nickname = ''
        this.form.mobile = ''
    }

    /** S Methods **/
    splitTime() {
        if (this.tableData != null) {
            this.form.start_time = this.tableData[0]
            this.form.end_time = this.tableData[1]
        }
    }
    // 重置
    onReset() {
        this.form = {
            apply_port_type: '',
            nickname: '', // 用户昵称
            sn: '', // 用户编号
            changeType: '', // 变动类型
            start_time: '',
            end_time: '',
            mobile: '' // 手机号
        }
        this.apply_port_type = '1'
        this.tableData = []

        this.getList()
    }
    // 资金记录
    getList(page?: number): void {
        page && (this.pager.page = page)
        this.pager
            .request({
                callback: apiAccountLog,
                params: {
                    ...this.form,
                    type: 'bw' // 类型 bnw-不可提现余额类型 integral-积分类型
                }
            })
            .then((res: any) => {
                this.apply_port_type = this.form.apply_port_type;
            })
    }

    // 变动类型数据
    getSearchList() {
        apiBwChangeType().then((res: any) => {
            this.changeTypeList = res
        })
    }
    /** E Methods **/

    /** S Life Cycle **/
    created() {
        this.getList()
        this.getSearchList()
    }
    /** E Life Cycle **/
}
